<template>
  <v-container
    class="pa-0"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-progress-linear
      v-if="isLoading"
      :indeterminate="true"
    />
    <div class="pa-3">
      <v-col>
        <v-row
          style="color: #2c2c2c"
          class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text mb-3"
        >
          {{ $t('deconve.today') }}
        </v-row>
        <v-row
          class="d-flex"
        >
          <bar-chart
            :chart-data="chartDataByHour"
            :options="optionsForChartByHour"
            style="display: flex; width: 100%"
            :height="320"
          />
        </v-row>
        <v-row
          style="color: #2c2c2c"
          class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text mb-3"
        >
          {{ $t('deconve.objectCounter.reportByDay') }}
        </v-row>
        <v-row class="mb-1">
          <v-col
            cols="12"
            sm="6"
          >
            <date-filter
              :start-date="startDate"
              :end-date="endDate"
              @change-start-date="changeStartDate"
              @change-end-date="changeEndDate"
            />
          </v-col>
        </v-row>
        <v-row
          class="d-flex"
        >
          <bar-chart
            :chart-data="chartDataByDay"
            :options="optionsForChartByDay"
            style="display: flex; width: 100%"
            :height="320"
          />
        </v-row>
      </v-col>
    </div>
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import DateFilter from '@/components/DateFilter.vue';
import BarChart from '@/components/BarChart';
import chartColors from '@/utils/colors';

export default {
  name: 'Reports',
  components: {
    BarChart,
    DateFilter,
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      chartDataByDay: {},
      chartDataByHour: {},
      optionsForChartByDay: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
        },
        scales: {
          xAxes: [{
            beginAtZero: true,
            stacked: true,
            gridLines: { display: false },
            ticks: {
              stepSize: 1,
            },
          }],
          yAxes: [{
            stacked: true,
          }],
        },
      },
      optionsForChartByHour: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
        },
        scales: {
          xAxes: [{
            beginAtZero: true,
            stacked: true,
            gridLines: { display: false },
            ticks: {
              stepSize: 1,
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('deconve.hour'),
            },
          }],
          yAxes: [{
            stacked: true,
          }],
        },
      },
      updateReportsTimer: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'objectcounter/isLoadingVideos',
      reportsByDayToChartData: 'objectcounter/reportsByDayToChartData',
      reportsByHourToChartData: 'objectcounter/reportsByHourToChartData',
    }),
  },
  watch: {
    reportsByDayToChartData() {
      this.chartDataByDay = this.addColorToChartData(this.reportsByDayToChartData);
    },
    reportsByHourToChartData() {
      this.chartDataByHour = this.addColorToChartData(this.reportsByHourToChartData);
    },
  },
  created() {
    this.$moment.locale(this.$i18n.locale);
    this.initDateRange();
    this.setTimerToUpdateReports();
  },
  beforeDestroy() {
    clearInterval(this.updateReportsTimer);
  },
  methods: {
    ...mapActions({
      getReportsByDay: 'objectcounter/getReportsByDay',
      getReportsByHour: 'objectcounter/getReportsByHour',
      getVideos: 'objectcounter/getVideos',
    }),
    getReports() {
      this.getVideos().then(() => {
        this.updateReportsByHour();
        this.updateReportsByDay();
      });
    },
    initDateRange() {
      const oneMonthBefore = this.$moment().subtract(1, 'month');

      this.startDate = oneMonthBefore.toISOString();
      this.endDate = this.$moment().toISOString();
      this.getReports();
    },
    updateReportsByDay() {
      const params = {
        startDate: this.$moment(this.startDate).startOf('day').toISOString(),
        endDate: this.$moment(this.endDate).endOf('day').toISOString(),
      };

      this.getReportsByDay(params);
    },
    updateReportsByHour() {
      const today = this.$moment();
      const params = {
        startDate: today.startOf('day').toISOString(),
        endDate: today.endOf('day').toISOString(),
      };

      this.getReportsByHour(params);
    },
    changeStartDate(newDate) {
      this.startDate = newDate.toISOString();
      this.updateReportsByDay();
    },
    changeEndDate(newDate) {
      this.endDate = newDate.toISOString();
      this.updateReportsByDay();
    },
    addColorToChartData(chartData) {
      const { labels, datasets } = chartData;
      const colors = chartColors(datasets.length);

      datasets.forEach((dataset, index) => {
        datasets[index] = { ...dataset, backgroundColor: colors[index] };
      });

      return { labels, datasets };
    },
    setTimerToUpdateReports() {
      const fiveMinutes = 5 * 60 * 1000;

      this.updateReportsTimer = setInterval(this.getReports, fiveMinutes);
    },
  },
};
</script>
